import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/product'

import { Segment } from 'semantic-ui-react'



const Thanks = () => (
  <Layout>
    <Segment>
    Thanks for contacting us we will get back to you shortly
    <br/>
    <Link to="/">Go back to the homepage</Link>
    </Segment>
  </Layout>
)

export default Thanks
